
import * as netlifyRuntime$Yr4bbukEEC from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'
import * as unsplashRuntime$kLpl6gItY3 from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxt/image/dist/runtime/providers/unsplash'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "netlify",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['netlify']: { provider: netlifyRuntime$Yr4bbukEEC, defaults: {} },
  ['unsplash']: { provider: unsplashRuntime$kLpl6gItY3, defaults: undefined }
}
        